<template>
  <component
    :is="computedElement"
    v-bind="props.type === 'avatar' ? { role: 'img' } : null"
    class="bitts-bone"
    :class="bittsBoneClass"
    :aria-label="`Placeholder for missing content in a ${computedElement} tag`"
  />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';

import { BittsSize } from '../types';

import { BittsBoneType, IBittsBoneType } from './types';

export type Props = {
  type?: IBittsBoneType;
  size?: Exclude<BittsSize, 'x-large'>;
  width?: number | string;
  height?: number | string | null;
  shimmer?: 'fast' | 'slow' | null;
};

const props = withDefaults(defineProps<Props>(), {
  type: BittsBoneType.Copy,
  size: 'medium',
  width: 100,
  height: null,
  shimmer: null,
});

const addPixels = (val: number | string) => `${val}px`;

const computedElement = computed(
  () =>
    ({
      [BittsBoneType.Avatar]: 'div',
      [BittsBoneType.Heading]: 'h2',
      [BittsBoneType.Copy]: 'p',
    })[props.type],
);

const bittsBoneClass = computed(() => ({
  [`bitts-bone__${props.type}`]: true,
  animate: !!props.shimmer,
}));

const formattedWidth = computed(() => {
  const width = Number(props.width);
  return width > 100 ? 100 : width < 0 ? 0 : width;
});

watch(
  () => props.width,
  () => {
    if (Number(props.width) < 0 || Number(props.width) > 100) {
      console.warn('BittsBone width prop must be between 0 and 100');
    }
  },
);
const computedWidth = computed(() => `${formattedWidth.value}%`);

const computedHeight = computed(() => {
  if (props.height) return addPixels(props.height);
  else if (props.type === BittsBoneType.Heading) return '16px';
  return '12px';
});

const sizeLookup = {
  large: 80,
  medium: 40,
  small: 24,
  'x-small': 16,
  tiny: 12,
};
const computedSize = computed(() => addPixels(sizeLookup[props.size]));

const radiusLookup = {
  large: 16,
  medium: 8,
  small: 4,
  'x-small': 3,
  tiny: 3,
};
const avatarRadius = computed(() => addPixels(radiusLookup[props.size]));

const animationDuration = computed(() =>
  props.shimmer === 'fast' ? '0.5s' : '1.2s',
);
</script>

<style scoped lang="pcss">
.bitts-bone {
  &.animate {
    background-size: 300% 100% !important;
    background: linear-gradient(
      135deg,
      theme(colors.neutral.background-disabled) 0%,
      theme(colors.neutral.background-disabled / 0.1) 100%
    );
    animation: shimmer-les-bones v-bind(animationDuration) ease infinite;
  }

  &.bitts-bone__avatar {
    /* setting font size to 0 to hide alt text but keep in dom for accessibility  */
    font-size: 0px;
    background: linear-gradient(
      135deg,
      theme(colors.neutral.background-disabled),
      theme(colors.neutral.background-disabled / 0.1) 100%
    );
    border-radius: v-bind(avatarRadius);
    width: v-bind(computedSize);
    height: v-bind(computedSize);
    min-width: v-bind(computedSize);
    min-height: v-bind(computedSize);
  }

  &.bitts-bone__heading,
  &.bitts-bone__copy {
    @apply rounded-3xl;
    width: v-bind(computedWidth);
  }

  &.bitts-bone__copy {
    height: v-bind(computedHeight);
  }

  &.bitts-bone__heading {
    height: v-bind(computedHeight);
  }

  &:not(.animate).bitts-bone__heading {
    @apply bg-neutral-background-disabled;
  }

  &:not(.animate).bitts-bone__copy {
    @apply bg-neutral-background;
  }
}

@keyframes shimmer-les-bones {
  0% {
    background-position: 0% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
